// extracted by mini-css-extract-plugin
export var awardCard = "cW_vL";
export var awardCard__bdg_large = "cW_vQ";
export var awardCard__bdg_small = "cW_vR";
export var awardCard__container = "cW_vX";
export var awardCard__description = "cW_vW";
export var awardCard__info = "cW_vS";
export var awardCard__large = "cW_vN";
export var awardCard__link = "cW_vV";
export var awardCard__post = "cW_vM";
export var awardCard__small = "cW_vP";
export var awardCard__title = "cW_vT";