import HeadPagesBlock from '~/components/blocks/HeadPagesBlock'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import { AwardsPageProps } from '~/pages/awards'

import AwardsPost from './components/AwardsPost'

const Awards = ({ data }: AwardsPageProps) => (
  <MainLayout>
    <HeadPagesBlock
      title="Industry Recognition"
      description="Independent ratings and research firms recognize our work."
    />

    <AwardsPost data={data} />

    <PrimeContactFormSection title="Let’s turn your idea into a successful product!" />
  </MainLayout>
)
export default Awards
