import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

import { AwardsPageProps } from '~/pages/awards'
import { cx, fileToImageLikeData } from '~/utils'

import * as containerStyles from './AwardsPost.module.scss'

const AwardsPost = ({ data }: AwardsPageProps) => {
  const sortingAwards = [...data.allStrapiAward.edges].sort((a, b) =>
    a.node.size!.localeCompare(b.node.size as string),
  )

  return (
    <div className={containerStyles.awardCard}>
      <div className={cx('container', containerStyles.awardCard__container)}>
        <div className="row">
          {sortingAwards.map(({ node: post }, index) => (
            <div
              key={post.title}
              className={
                post.size === 'large' ? 'col-12' : 'col-12 col-md-6 col-lg-4'
              }
            >
              <div
                className={cx(
                  containerStyles.awardCard__post,
                  containerStyles[`awardCard__${post.size}`],
                )}
              >
                {post.badge && (
                  <div
                    className={containerStyles[`awardCard__bdg_${post.size}`]}
                  >
                    {React.isValidElement(post.badge.localFile) ? (
                      post.badge.localFile
                    ) : (
                      <GatsbyImage
                        image={
                          getImage(
                            fileToImageLikeData(post.badge.localFile),
                          ) as IGatsbyImageData
                        }
                        alt={`${post.title} | Codica`}
                        title={`${post.title}`}
                        loading={index === 0 ? 'eager' : 'lazy'}
                      />
                    )}
                  </div>
                )}
                <div className={containerStyles.awardCard__info}>
                  <div className={containerStyles.awardCard__title}>
                    {post.link ? (
                      <a
                        target="_blank"
                        aria-label={`${post.title}`}
                        href={post.link as string | undefined}
                        rel={post.link_attributes as string | undefined}
                        className={containerStyles.awardCard__link}
                      >
                        {post.title}
                      </a>
                    ) : (
                      post.title
                    )}
                  </div>
                  {post.description && (
                    <p className={containerStyles.awardCard__description}>
                      {post.description}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AwardsPost
