import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { AllStrapiAward, QueryResult, StrapiPage } from '~/types/graphql'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import Awards from '~/views/Awards/Awards.view'

export type AwardsPageProps = QueryResult<StrapiPage & AllStrapiAward>

const AwardsPage = ({ data }: AwardsPageProps) => <Awards data={data} />
export default AwardsPage

export const query = graphql`
  query {
    strapiPage(page_name: { eq: "Awards" }) {
      ...MetaTagInformationPages
    }

    allStrapiAward(sort: { updatedAt: DESC }) {
      edges {
        node {
          id
          title
          description
          size
          link
          link_attributes
          badge {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`

export const Head = ({ data }: AwardsPageProps) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiPage,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image?.localFile)) as string}
      url="https://www.codica.com/awards/"
    />
  )
}
